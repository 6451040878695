.home-bg .home-bg-image-item {
  display: inline-block;
  height: 50px;
  position: absolute;
  transform-style: flat;
  width: 50px;
  will-change: transform, z-index;
}

.home-bg .home-bg-image-item {
  animation: bg-item-animate 25s linear infinite;
  animation-delay: 0s;
  transform: translateY(230px) translateX(452.16294005px) translateZ(100px);
  z-index: 46;
}

.home-bg {
  height: 750px;
  background-color: black;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
* {
  box-sizing: border-box;
}
* {
  margin: 0;
}

div {
  display: block;
  unicode-bidi: isolate;
}

:root {
  --phone-rotation-init: scale(var(--phone-transform-scale)) rotateX(0)
    rotateY(-90deg) rotateZ(10deg);
  --phone-rotation-left: scale(var(--phone-transform-scale)) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg);
  --phone-rotation-right: scale(var(--phone-transform-scale)) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg);
  --phone-content-transform: translateZ(190px);
  --phone-content-cover-transform: translateZ(240px);
}

::-webkit-scrollbar {
  display: none;
}

.home-bg .home-bg-image-list {
  height: 100vw;
  position: absolute;
  width: 100vw;
}

.home-bg .home-bg-image-list-rotate {
  height: 460px;
  perspective: 117px;
  perspective-origin: 14% 56%;
  transform: translateY(13vh) translateX(17vw);
  width: 460px;
}

@media screen and (max-width: 768px) {
  .home-bg .home-bg-image-list-rotate {
    height: 460px;
    perspective: 140px;
    perspective-origin: 14% 56%;
    transform: translateY(17vh) translateX(17vw);
    width: 460px;
  }
  .home-bg .home-bg-image-item {
    display: inline-block;
    height: 250px;
    position: absolute;
    transform-style: preserve-3d;
    width: 250px;
    will-change: transform, opacity;
    animation: bg-item-animate 18s linear infinite, fade-in 5s ease-out forwards;
  }

  .home-bg .home-bg-image-item {
    display: inline-block;
    height: 250px;
    position: absolute;
    transform-style: flat;
    width: 250px;
    will-change: transform, z-index;
  }
}

.home-bg .home-bg-image-item {
  display: inline-block;
  height: 50px;
  position: absolute;
  transform-style: preserve-3d;
  width: 50px;
  will-change: transform, opacity;
  animation: bg-item-animate 18s linear infinite, fade-in 5s ease-out forwards;
}

.home-bg .home-bg-image-item .home-bg-image {
  animation: bg-image-rotate-2 8s linear infinite;
}

.home-bg .home-bg-image-item:nth-child(even) .home-bg-image {
  animation-direction: reverse;
}
.home-bg .home-bg-image {
  display: inline-block;
  height: 50px;
  width: 50px;
  will-change: transform;
}

.home-bg .home-bg-image-item:nth-child(2n) {
  animation-delay: -5s;
}
.home-bg .home-bg-image-item:nth-child(3n) {
  animation-delay: -10s;
}
.home-bg .home-bg-image-item:nth-child(5n) {
  animation-delay: -15s;
}
.home-bg .home-bg-image-item:nth-child(7n) {
  animation-delay: -20s;
}
.home-bg .home-bg-image-item:nth-child(odd) {
  z-index: 50;
}
.home-bg .home-bg-image-item:nth-child(even) {
  z-index: 40;
}

@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.flex-item {
  animation: slideInFromLeft 0.5s ease-out;
}

@media screen and (min-width: 1020px) {
  .home-bg {
    display: block;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes bg-image-rotate-2 {
  0% {
    transform: rotateX(-15deg) rotateY(-10deg) rotate(5deg);
  }
  25% {
    transform: rotateX(-10deg) rotateY(15deg) rotate(12deg);
  }
  75% {
    transform: rotateX(-20deg) rotateY(20deg) rotate(-10deg);
  }
  100% {
    transform: rotateX(-15deg) rotateY(-10deg) rotate(5deg);
  }
}

@keyframes bg-item-animate {
  0% {
    transform: translateY(230px) translateX(452.16294005px) translateZ(100px);
    z-index: 46;
  }
  2.77778% {
    transform: translateY(207.09188433px) translateX(440.32021539px)
      translateZ(96.52703645px);
    z-index: 47;
  }
  5.55556% {
    transform: translateY(184.87982016px) translateX(422.08701742px)
      translateZ(93.15959713px);
    z-index: 48;
  }
  8.33333% {
    transform: translateY(164.03870982px) translateX(398.01735262px)
      translateZ(90px);
    z-index: 49;
  }
  11.1111% {
    transform: translateY(145.20179991px) translateX(368.84256559px)
      translateZ(87.14424781px);
    z-index: 50;
  }
  13.8889% {
    transform: translateY(128.94144039px) translateX(335.44911745px)
      translateZ(84.67911114px);
    z-index: 51;
  }
  16.6667% {
    transform: translateY(115.75169407px) translateX(298.85165125px)
      translateZ(82.67949192px);
    z-index: 52;
  }
  19.4444% {
    transform: translateY(106.03332472px) translateX(260.16216246px)
      translateZ(81.20614758px);
    z-index: 53;
  }
  22.2222% {
    transform: translateY(100.08162006px) translateX(220.55621163px)
      translateZ(80.30384494px);
    z-index: 54;
  }
  25% {
    transform: translateY(98.07741964px) translateX(181.23720552px)
      translateZ(80px);
    z-index: 55;
  }
  27.7778% {
    transform: translateY(100.08162006px) translateX(143.39983226px)
      translateZ(80.30384494px);
    z-index: 56;
  }
  30.5556% {
    transform: translateY(106.03332472px) translateX(108.19376127px)
      translateZ(81.20614758px);
    z-index: 57;
  }
  33.3333% {
    transform: translateY(115.75169407px) translateX(76.6887112px)
      translateZ(82.67949192px);
    z-index: 58;
  }
  36.1111% {
    transform: translateY(128.94144039px) translateX(49.84194707px)
      translateZ(84.67911114px);
    z-index: 59;
  }
  38.8889% {
    transform: translateY(145.20179991px) translateX(28.46919421px)
      translateZ(87.14424781px);
    z-index: 60;
  }
  41.6667% {
    transform: translateY(164.03870982px) translateX(13.2198529px)
      translateZ(90px);
    z-index: 61;
  }
  44.4444% {
    transform: translateY(184.87982016px) translateX(4.55726667px)
      translateZ(93.15959713px);
    z-index: 62;
  }
  47.2222% {
    transform: translateY(207.09188433px) translateX(2.74464381px)
      translateZ(96.52703645px);
    z-index: 63;
  }
  50% {
    transform: translateY(230px) translateX(7.83705995px) translateZ(100px);
    z-index: 64;
  }
  52.7778% {
    transform: translateY(252.90811567px) translateX(19.67978461px)
      translateZ(103.47296355px);
    z-index: 65;
  }
  55.5556% {
    transform: translateY(275.12017984px) translateX(37.91298258px)
      translateZ(106.84040287px);
    z-index: 66;
  }
  58.3333% {
    transform: translateY(295.96129018px) translateX(61.98264738px)
      translateZ(110px);
    z-index: 67;
  }
  61.1111% {
    transform: translateY(314.79820009px) translateX(91.15743441px)
      translateZ(112.85575219px);
    z-index: 68;
  }
  63.8889% {
    transform: translateY(331.05855961px) translateX(124.55088255px)
      translateZ(115.32088886px);
    z-index: 69;
  }
  66.6667% {
    transform: translateY(344.24830593px) translateX(161.14834875px)
      translateZ(117.32050808px);
    z-index: 70;
  }
  69.4444% {
    transform: translateY(353.96667528px) translateX(199.83783754px)
      translateZ(118.79385242px);
    z-index: 71;
  }
  72.2222% {
    transform: translateY(359.91837994px) translateX(239.44378837px)
      translateZ(119.69615506px);
    z-index: 36;
  }
  75% {
    transform: translateY(361.92258036px) translateX(278.76279448px)
      translateZ(120px);
    z-index: 37;
  }
  77.7778% {
    transform: translateY(359.91837994px) translateX(316.60016774px)
      translateZ(119.69615506px);
    z-index: 38;
  }
  80.5556% {
    transform: translateY(353.96667528px) translateX(351.80623873px)
      translateZ(118.79385242px);
    z-index: 39;
  }
  83.3333% {
    transform: translateY(344.24830593px) translateX(383.3112888px)
      translateZ(117.32050808px);
    z-index: 40;
  }
  86.1111% {
    transform: translateY(331.05855961px) translateX(410.15805293px)
      translateZ(115.32088886px);
    z-index: 41;
  }
  88.8889% {
    transform: translateY(314.79820009px) translateX(431.53080579px)
      translateZ(112.85575219px);
    z-index: 42;
  }
  91.6667% {
    transform: translateY(295.96129018px) translateX(446.7801471px)
      translateZ(110px);
    z-index: 43;
  }
  94.4444% {
    transform: translateY(275.12017984px) translateX(455.44273333px)
      translateZ(106.84040287px);
    z-index: 44;
  }
  97.2222% {
    transform: translateY(252.90811567px) translateX(457.25535619px)
      translateZ(103.47296355px);
    z-index: 45;
  }
  100% {
    transform: translateY(230px) translateX(452.16294005px) translateZ(100px);
    z-index: 46;
  }
}
@keyframes shine {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

@keyframes floatingShine {
  0% {
    top: -100%;
  }
  100% {
    top: 100%;
  }
}

.sponsor-button {
  position: relative;
  overflow: hidden;
}

.sponsor-button .shine {
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.3),
    transparent
  );
  animation: shine 3s linear infinite;
}

.sponsor-button .floating-shine {
  position: absolute;
  top: -100%;
  left: 50%;
  width: 10px;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  animation: floatingShine 2s linear infinite;
  transform: translateX(-50%);
}

.animate-text span {
  opacity: 0;
  display: inline-block;
  animation: appear 1s forwards;
}

.animate-text span:nth-child(1) { animation-delay: 0s; }
.animate-text span:nth-child(2) { animation-delay: 0.1s; }
.animate-text span:nth-child(3) { animation-delay: 0.2s; }
.animate-text span:nth-child(4) { animation-delay: 0.3s; }
.animate-text span:nth-child(5) { animation-delay: 0.4s; }
.animate-text span:nth-child(6) { animation-delay: 0.5s; }
.animate-text span:nth-child(7) { animation-delay: 0.6s; }
.animate-text span:nth-child(8) { animation-delay: 0.7s; }
.animate-text span:nth-child(9) { animation-delay: 0.8s; }
.animate-text span:nth-child(10) { animation-delay: 0.9s; }
.animate-text span:nth-child(11) { animation-delay: 1s; }

.flame-text {
  display: inline-block;
  animation: flame 1.2s infinite alternate;
}

@keyframes appear {
  0% { opacity: 0; transform: translateY(-20px); }
  100% { opacity: 1; transform: translateY(0); }
}

@keyframes flame {
  0% { transform: scale(1); }
  100% { transform: scale(1.1); }
}